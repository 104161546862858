.sd-product-grid {
  .elc-grid-container {
    .elc-product-grid--header {
      justify-content: space-between;
    }
    .elc-sort-wrapper {
      margin-left: auto;
    }
    .elc-product-grid--filter-results-count-wrapper {
      display: inline-block;
      width: auto;
      padding: 0;
      font-size: 14px;
      vertical-align: bottom;
      line-height: 2;
    }
    .elc-product-grid--selected-filter-values-wrapper {
      display: inline;
      .elc-button {
        @include swap_direction(margin, 0 6px 0 0);
        line-height: 0.5;
        height: 22px;
        padding: 5px 10px;
        font-family: $base-font-family;
        font-size: 14px;
        text-transform: capitalize;
        border: 0;
        .elc-remove-icon {
          -webkit-mask-image: url(/media/images/global/icons/close_white.svg);
          mask-image: url(/media/images/global/icons/close_white.svg);
          height: 8px;
          width: 8px;
          background: $color-white;
        }
        &:hover {
          color: $color-white;
        }
      }
      &::before {
        content: '\A';
        display: block;
      }
    }
  }
}

.elc-product-grid--filters-section,
.elc-mobile-filters-modal {
  .elc-filter-by-label {
    display: inline-block;
    font-family: $header-font-bold;
    font-size: 26px;
    height: auto;
    vertical-align: bottom;
    margin-#{$rdirection}: 28px;
    text-transform: uppercase;
    line-height: 2;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  .elc-product-grid--clear-all {
    color: $color-black;
    background: transparent;
    font-family: $base-font-family;
    text-decoration: underline;
    display: inline-block;
    width: auto;
    line-height: 1;
    padding: 0;
    text-transform: capitalize;
    transform: translateX(-50%);
    #{$ldirection}: 50%;
    position: relative;
    .elc-remove-icon {
      display: none;
    }
    &:hover {
      background: transparent;
    }
    @include breakpoint($medium-up) {
      margin: 0;
      transform: none;
      padding: 0 20px;
      position: static;
    }
  }
  .elc-product-grid--apply-filter {
    #{$ldirection}: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    width: calc(100% - 40px);
    position: relative;
    .elc-product-grid--apply-filter-label {
      color: $color-white;
      font-family: $base-font-family;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
    }
  }
}

.elc-product-grid--filter-set-wrapper {
  font-family: $base-font-family;
  font-size: 14px;
  margin-#{$rdirection}: 10px;
  position: relative;
  overflow: hidden;
  @include breakpoint($medium-up) {
    display: inline-block;
    overflow: visible;
  }
  .elc-button {
    background: transparent;
    border: 0;
    color: $color-black;
    font-family: $base-font-family;
    font-size: 14px;
    padding: 0;
    text-transform: none;
    line-height: 2;
    height: auto;
    padding-#{$rdirection}: 38px;
    position: relative;
    &:hover {
      background: transparent;
    }
    &::after {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      border: solid $color-black;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      #{$rdirection}: 18px;
      top: 8px;
    }
    &.active {
      &::after {
        transform: rotate(-135deg);
        top: 14px;
      }
    }
    .elc-styled-filters-active-filters-no {
      display: none;
    }
  }
  .elc-product-grid--filter-options-wrapper {
    font-family: $base-font-family;
    font-size: 16px;
    z-index: 100;
    background: $color-white;
    width: 100%;
    min-width: 192px;
    @include breakpoint($medium-up) {
      border: 1px solid $color-light-gray;
      position: absolute;
      padding: 12px;
      #{$ldirection}: -17px;
    }
  }
  .elc-product-grid--filter-option {
    margin: 0;
    .elc-checkbox {
      margin-bottom: 14px;
      width: 50%;
      float: $ldirection;
      line-height: 1;
      @include breakpoint($medium-up) {
        display: block;
        width: auto;
        float: none;
      }
      &-label {
        font-family: $base-font-family;
        font-weight: normal;
        letter-spacing: 1px;
        &:hover {
          color: $cr19-dark-pink;
        }
      }
      &-check-area {
        width: 15px;
        height: 15px;
        border: 1px solid;
        top: 1px;
        margin: 5px;
      }
      &-icon {
        -webkit-mask-image: none;
        mask-image: none;
        width: 100%;
        height: 100%;
        border: 1px solid $color-white;
        background: transparent;
      }
      &-selected {
        .elc-checkbox-icon {
          background-color: $cr19-dark-pink;
        }
      }
    }
  }
  label {
    font-weight: bold;
    line-height: 3;
    .elc-styled-filters-active-filters-no {
      display: none;
    }
    @include breakpoint($medium-up) {
      line-height: 1;
    }
  }
}

.elc-mobile-filters-modal {
  #{$ldirection}: 0;
  max-width: 100%;
  min-height: 100%;
  min-width: 310px;
  padding-bottom: 50px;
  top: 0;
  transform: none;
  .elc-overlay-wrapper-close-button {
    background: transparent;
    float: $rdirection;
    &:hover {
      background-color: transparent;
    }
  }
}

.elc-filter-by-label {
  font-family: $header-font-bold;
  font-size: 27px;
}

.elc-applied-filters-number {
  display: none;
}

.product-grid--header-mobile {
  .elc-orderable-filters-wrapper {
    width: 100%;
    .elc-product-grid--filter-results-count-wrapper {
      line-height: 1;
    }
    .elc-product-grid--selected-filter-values-wrapper {
      float: $ldirection;
      padding: 13px 0 0;
      .elc-button {
        @include swap_direction(margin, 0 10px 10px 0);
        padding: 13px;
        height: auto;
      }
    }
    .elc-product-grid--filters-modal-button {
      width: 47%;
      display: flex;
      justify-content: center;
      border: 1px solid $color-light-gray;
    }
    .elc-filter-icon {
      display: inline-block;
      margin-#{$ldirection}: 10px;
      height: 100%;
    }
  }
  .elc-orderable-filters-wrapper ~ .elc-sort-wrapper {
    width: 47%;
    position: absolute;
    #{$rdirection}: 10px;
    select {
      height: 40px;
      text-transform: uppercase;
      font-family: $header-font-bold;
      border-color: $color-light-gray;
    }
  }
  .elc-product-grid--clear-all {
    position: static;
    float: $rdirection;
    transform: none;
    height: auto;
    margin: 0;
  }
}
