/**
 * @file standalone/sd_grid_formatter.scss
 *
 * \brief Library file - shared styles for stardust product grid
 *
*/

@import '../theme-bootstrap';
@import '../components/mpp-filters';

.sd-product-grid,
.sd-search-results,
.elc-search-grid-wrapper {
  font-family: $base-font-family;
  min-height: 500px;
  @include breakpoint($landscape-up) {
    margin: 40px;
  }
  .elc-product {
    &-brief-detail-wrapper {
      padding-top: 35px;
      justify-content: flex-start;
      & > div {
        width: 100%;
      }
      .elc-add-to-bag-sku-inventory-status-message {
        order: 7;
        margin-top: -45px;
      }
      .elc-product {
        &-product-name-wrapper {
          order: 1;
          margin-top: 10px;
          line-height: 20px;
          .elc-product-name-wrapper {
            margin: 0;
          }
        }
        &-rating-wrapper {
          height: 20px;
          order: 2;
          margin-top: 5px;
          @include breakpoint($landscape-up) {
            height: 30px;
          }
        }
        &-description-wrapper {
          order: 3;
        }
        &-price-wrapper {
          order: 4;
        }
        &-size-wrapper {
          order: 5;
        }
        &-cta-wrapper {
          order: 6;
        }
      }
    }
    &-grid-header {
      @include text-title--large;
      text-align: center;
    }
    &-grid-subheader {
      font-family: $base-font-family;
      font-size: 16px;
      text-align: center;
      height: 55px;
    }
    &-grid-top-border {
      box-shadow: none;
      height: 45px;
    }
    &-brief-wrapper {
      box-shadow: none;
      padding: 0;
      @include breakpoint($landscape-up) {
        padding: 8px;
      }
      &:hover {
        box-shadow: none;
      }
      .elc-product {
        &-images-wrapper {
          height: 0;
          padding-top: 100%;
          position: relative;
          .elc-img {
            height: 100%;
            position: absolute;
            top: 0;
          }
        }
        &-display-name,
        &-description-wrapper {
          line-height: 1.2em;
          text-decoration: none;
          font-family: $base-font-family;
        }
        &-display-name {
          font-family: $header-font-bold;
          font-size: 20px;
          font-weight: 100;
        }
        &-shade-picker-wrapper {
          margin-top: -33px;
          height: 40px;
          .slick-slider {
            margin-bottom: 0;
          }
          .slick-list {
            width: 100%;
            margin: 0 auto;
          }
          .slick-track {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            .slick-slide {
              margin-#{$rdirection}: 5px;
              &:last-child {
                margin-#{$rdirection}: 0;
              }
            }
            .elc-shade-image {
              width: 20px;
              height: 20px;
            }
            .elc-shade-image-selected {
              &::after {
                content: '';
                box-shadow: inset 0 0 0 2px $color-white;
              }
            }
          }
          p {
            font-family: $header-font-bold;
            font-size: 14px;
            color: $color-black;
            text-transform: uppercase;
          }
        }
        &-product-name-wrapper {
          &:first-of-type ~ .elc-product-shade-picker-wrapper:nth-of-type(4) {
            margin-top: -45px;
          }
        }
        &-price-wrapper {
          .elc-product-price-row-wrapper {
            padding-top: 0;
            justify-content: center;
          }
          .elc-product-original-price,
          .elc-product-price,
          .elc-product-price-range {
            font-size: 16px;
            font-weight: 100;
            font-family: $header-font-bold;
            text-align: center;
          }
          .elc-product-original-price {
            color: $color-black;
          }
          .elc-product-price {
            color: $color-red;
          }
        }
        &-size-wrapper {
          margin-top: 0;
          @include breakpoint($landscape-up) {
            margin-top: unset;
          }
          .slick-slider {
            margin-bottom: 0;
          }
          .elc-slider-arrow-wrapper {
            &:before {
              display: none;
            }
          }
          .slick-slide:only-child {
            .elc-size-picker-box-selected {
              border: none;
              font-weight: normal;
              font-family: $base-font-family;
            }
          }
        }
        &-subheader {
          font-family: $base-font-family;
          font-size: 16px;
          margin: 0;
        }
        &-description-wrapper {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          min-height: 40px;
          margin-bottom: 0;
          margin-top: 15px;
          @include breakpoint($landscape-up) {
            margin-top: unset;
            min-height: 25px;
          }
        }
        &-rating-wrapper {
          & > div {
            justify-content: center;
          }
          .elc-rating {
            margin: 4px 0 14px;
            &-reviews {
              @include swap_direction(margin, 0 0 0 -4px);
            }
          }
          .elc-read-reviews {
            display: none;
          }
          .elc-reviews-number {
            font-family: inherit;
            font-size: 16px;
            color: $color-black;
            font-weight: normal;
          }
          .elc-star-icon {
            width: 18px;
            height: 18px;
          }
          .elc-star-overlay {
            background-color: $cr19-dark-pink;
            background: url('/sites/glamglowmud/themes/gg_base/img/icons/src/gg_star_pink.svg') 95% 50% no-repeat;
            -webkit-mask: none;
          }
        }
        &-cta-wrapper {
          margin: 10px auto 30px;
          padding-top: 0;
          width: 320px;
          @include breakpoint($landscape-up) {
            margin-top: auto;
          }
          .elc-add-to-bag-button {
            padding: 0 30px;
            font-family: $header-font-bold;
            background-color: $cr19-dark-pink;
            border-color: $cr19-dark-pink;
            color: $color-white;
            overflow: unset;
            text-transform: uppercase;
          }
          .elc-add-to-bag-button-disabled {
            background-color: $cr19-bg-dark;
            border-color: $cr19-bg-dark;
          }
          .elc-add-to-bag-button,
          .elc-add-to-bag-button-disabled {
            width: 320px;
            @include breakpoint($landscape-up) {
              width: auto;
              margin: auto;
              order: 1;
            }
          }
          .elc-add-to-bag-sku-inventory-status-message {
            order: 2;
            margin: 0;
            width: 100%;
            position: absolute;
            bottom: 40px;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            & + .elc-add-to-bag-button {
              background-color: $cr19-bg-dark;
              border-color: $cr19-bg-dark;
              cursor: not-allowed;
            }
          }
          .elc-add-to-bag-sku-inventory-status-message-message {
            color: $cr19-error;
          }
          .elc-product-cta-button-wrapper {
            div {
              display: flex;
              flex-direction: column;
            }
          }
          .elc-limited-remaining-block {
            margin: 15px 0;
          }
          .elc-limited-remaining-message {
            @include main-font;
            color: $color-red;
          }
        }
      }
    }
    &-carousel-slider-view {
      .elc-directional-icon {
        background: url('#{$base-theme-path}img/icons/src/arrow_black.svg') 100% no-repeat;
        -webkit-mask: none;
        height: 38px;
        width: 42px;
        &.elc-slider-arrow-left {
          transform: rotate(0);
        }
        &.elc-slider-arrow-right {
          transform: rotate(180deg);
        }
      }
      .slick-arrow {
        &.slick-prev {
          @include breakpoint($medium-up) {
            #{$ldirection}: -15px;
          }
        }
        &.slick-next {
          @include breakpoint($medium-up) {
            #{$rdirection}: -15px;
          }
        }
      }
    }
    .elc-size-picker-wrapper {
      font-family: inherit;
      font-size: 14px;
      line-height: 1em;
      > div:nth-child(2) {
        flex-wrap: wrap;
      }
      .elc-size-picker-box-selected,
      .elc-size-picker-box {
        @include swap_direction(margin, 5px 5px 5px 0);
        border-radius: 0;
        width: auto;
        padding: 8px 12px;
        @include breakpoint($landscape-up) {
          padding: 8px 20px;
        }
      }
      .elc-size-picker-box {
        border: 1px solid $color-light-gray;
      }
      .elc-size-picker-box-selected {
        border: 3px solid $cr19-dark-pink;
        padding: 7px 12px;
        color: $color-black;
        background-color: $color-white;
        font-weight: bold;
      }
      .slick-track {
        flex-wrap: wrap;
      }
      .slick-slide {
        width: auto !important;
      }
    }
  }
  .elc-grid-container {
    margin: 0 0 40px;
    @include breakpoint($landscape-up) {
      margin: 0 10px;
    }
    .elc-grid-item-product {
      padding: 0;
    }
  }
  .elc-dropdown {
    &-wrapper,
    &-arrow {
      border: none;
      &:hover {
        background: none;
      }
    }
    &-arrow {
      padding: 0;
      &[aria-label='close menu'] {
        .elc-dropdown-arrow-icon {
          background: url('/sites/glamglowmud/themes/gg_base/img/icons/src/arrow_black.svg') no-repeat 50% 50%;
          transform: rotate(90deg);
        }
      }
    }
    &-arrow-icon {
      background: url('/sites/glamglowmud/themes/gg_base/img/icons/src/arrow_black.svg') no-repeat 50% 50%;
      width: 14px;
      height: 14px;
      -webkit-mask: none;
      transform: rotate(-90deg);
    }
    &-readonly-input {
      justify-content: flex-end;
      font-size: 14px;
      text-transform: uppercase;
      color: $color-black;
      font-family: $header-font-bold;
    }
    &-options-list {
      width: 214px;
      #{$ldirection}: auto;
      top: -41px;
      max-height: 250px;
      #{$rdirection}: -14px;
    }
    &-option {
      font-size: 14px;
      line-height: 22px;
      font-family: $base-font-family;
      color: $color-black;
      font-weight: 300;
      padding: 10px 20px 15px 27px;
      &:first-child {
        text-align: end;
        text-transform: uppercase;
        font-family: $header-font-bold;
        background: none;
        &:after {
          content: '';
          border: solid $color-black;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 3px;
          margin-#{$ldirection}: 8px;
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }
    }
  }
  .elc-product-grid--header,
  .elc-styled-filters {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    box-shadow: none;
    .elc-dropdown-arrow-icon,
    .elc-dropdown-option:first-child:after {
      position: relative;
      display: inline-block;
      padding: 0;
      background: none;
      -webkit-mask: none;
      content: '';
      width: 9px;
      height: 9px;
      border: solid $color-black;
      border-width: 0 1px 1px 0;
    }
    .elc-dropdown-arrow-icon {
      top: -2px;
      margin-#{$ldirection}: 1px;
      transform: rotate(45deg);
    }
    .elc-dropdown-option {
      @include swap_direction(padding, 10px 14px 15px 27px);
      &:first-child:after {
        top: 2px;
        transform: rotate(-135deg);
      }
    }
  }
}

.breadcrumbs {
  max-width: none;
  background-color: $color-white;
  padding: 10px 20px;
  @include breakpoint($medium-up) {
    margin: 6px 40px;
  }
  &__level {
    font-size: 14px;
    text-decoration: none;
    &:before {
      content: ' /';
    }
    &:first-child:before {
      content: '< ';
      font-size: 16px;
      @include breakpoint($medium-up) {
        content: '';
      }
    }
    &:last-child:before {
      content: '';
    }
    a {
      @include main-font-book;
      font-size: 14px;
      color: $color-gray-dark-alt;
      text-decoration: none;
      letter-spacing: 1px;
      line-height: 38px;
      &:hover {
        color: $cr19-dark-pink;
      }
    }
    h1 {
      display: inline-block;
      font-size: 14px;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-rendering: auto;
      -webkit-font-smoothing: auto;
      a {
        color: $color-gray-light-text;
      }
    }
  }
}

.breadcrumb__level--count {
  float: $rdirection;
  min-width: 86px; // iOS style fix
}

.sd-mount-skeleton {
    img.elc-img {
      display: block;
      width: auto;
  }
  .sd-grid-skeleton {
    @include breakpoint($large-up) {
      padding: 0 10px;
    }
    .sd-grid-skeleton-filters {
      padding: 0 8px;
      .skeleton-bar {
        height: 30px;
      }
    }
    .sd-grid-skeleton-items {
      .skeleton-item {
        padding: 0 0 75px;
        @include breakpoint($medium-up) {
          padding: 0 0 90px;
        }
        @include breakpoint($large-up) {
          padding: 0 8px 90px;
        }
        .skeleton {
          &-details {
            padding-top: 20px;
            @include breakpoint($medium-up) {
              padding-top: 40px;
            }
          }
        }
      }
    }
  }
}

// New product brief styling
.sd-product-spp,
.sd-search-results,
.elc-search-enhanced-grid-wrapper,
.elc-search-results-wrapper,
.sd-product-grid {
  .elc-product {
    &-brief {
      box-shadow: none;
      padding: 0;
      position: relative;
      @include breakpoint($medium-up) {
        flex: 1 1 0%;
        height: auto;
        padding: 15px;
      }
      &:hover {
        box-shadow: none;
      }
      .elc-main-content-section {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        text-align: center;
        .elc-carousel-section-wrapper {
          margin: 20px auto 0;
          order: initial;
          width: 100%;
          .slick-slider {
            margin-bottom: 0;
          }
        }
      }
      a {
        text-decoration: none;
      }
      .elc-rating-component {
        height: 20px;
        margin: 10px 0;
        padding: 0 6px;
      }
      .elc-size-picker-container {
        margin-top: 0;
        @include breakpoint($landscape-up) {
          margin-top: unset;
        }
        .sd-search-gnav-input-field & {
          margin-bottom: 10px;
        }
        .slick-slider {
          margin-bottom: 0;
        }
        .elc-slider-arrow-wrapper {
          &:before {
            display: none;
          }
        }
        .slick-slide:only-child {
          .elc-size-picker-box-selected {
            border: none;
            font-weight: normal;
            font-family: $base-font-family;
          }
        }
        .elc-size-picker-wrapper {
          font-family: inherit;
          font-size: 14px;
          line-height: get-line-height(14px, 16px);
          .elc-selected-size-space-row:nth-child(1) {
            margin: 0;
          }
          > div:nth-child(2) {
            flex-wrap: wrap;
          }
          .elc-selected-size-space-row {
            display: none;
          }
          .elc-selected-size-space-row:only-child {
            display: block;
            .elc-selected-size {
              background-color: $color-white;
              color: $color-black;
              display: block;
              font-family: $base-font-family;
              font-size: 14px;
              font-weight: normal;
              margin: 0 auto;
              padding: 8px 12px;
              text-align: center;
              width: max-content;
            }
          }
          .elc-size-picker-box-selected,
          .elc-size-picker-box {
            @include swap_direction(margin, 5px 5px 5px 0);
            border-radius: 0;
            padding: 8px 12px;
            width: auto;
            @include breakpoint($landscape-up) {
              padding: 8px 20px;
            }
          }
          .elc-size-picker-box {
            border: 1px solid $color-light-gray;
            .sd-search-gnav-input-field & {
              color: $color-white;
            }
          }
          .elc-size-picker-box-selected {
            background-color: $color-white;
            color: $color-black;
            border: 3px solid $cr19-dark-pink;
            font-weight: bold;
            padding: 7px 12px;
          }
          .slick-track {
            flex-wrap: wrap;
          }
        }
      }
      .elc-shade-swatches-wrapper {
        margin-top: 10px;
        height: 40px;
        @include breakpoint($landscape-up) {
          height: 25px;
        }
        .slick-slider {
          margin-bottom: 0;
        }
        .slick-list {
          width: 100%;
          margin: 0 auto;
        }
        .slick-track {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          .slick-slide {
            margin-#{$rdirection}: 5px;
            &:last-child {
              margin-#{$rdirection}: 0;
            }
          }
          .elc-shade-image {
            width: 20px;
            height: 20px;
          }
        }
        p {
          font-family: $header-font-bold;
          font-size: 14px;
          color: $color-black;
          text-transform: uppercase;
        }
      }
      .elc-size-picker-wrapper,
      .elc-shade-swatches-wrapper {
        .slick-track {
          .slick-slide {
            width: auto !important;
          }
        }
      }
      .elc-product {
        &-images-wrapper {
          height: 0;
          padding-top: 100%;
          position: initial;
          .elc-img {
            height: 100%;
            position: absolute;
            top: 0;
          }
        }
        &-badge-wrapper {
          font-size: 14px;
          letter-spacing: 1px;
          line-height: get-line-height(14px, 22px);
          color: $cr19-dark-pink;
          font-family: $header-font-bold;
          font-weight: 100;
          text-transform: uppercase;
          z-index: 1;
          position: absolute;
          padding: 2px;
          box-sizing: border-box;
          align-items: center;
          display: flex;
        }
        &-image-badge-wrapper {
          .elc-check-visibility {
            height: 0;
            padding-top: 100%;
            overflow: hidden;
            position: relative;
          }
          .elc-product-badge-wrapper {
            height: 20px;
            #{$ldirection}: 20px;
            top: 37px;
          }
        }
        &-size-wrapper {
          order: 5;
        }
        &-name-wrapper {
          margin-top: 10px;
          .sd-search-gnav-input-field & {
            color: $color-white;
          }
        }
        &-name-wrapper,
        &-subheader,
        &-short-description-wrapper,
        &-prices-wrapper,
        &-prices-clickable-wrapper,
        .elc-size-picker-container {
          padding: 0 6px;
        }
        &-display-name-wrapper {
          justify-content: flex-start;
          margin-top: 10px;
          padding-top: 35px;
          .sd-search-gnav-input-field & {
            padding-top: 0;
          }
        }
        &-display-name-link {
          font-family: $header-font-bold;
          font-size: 20px;
          font-weight: 100;
          letter-spacing: 0.21px;
          line-height: get-line-height(20px, 19px);
          margin-bottom: 0;
          text-decoration: none;
        }
        &-subheader {
          font-family: $base-font-family;
          font-size: 16px;
          letter-spacing: 0.21px;
          line-height: get-line-height(16px, 32px);
          margin: 0;
        }
        &-rating-wrapper {
          order: 2;
          .elc-rating {
            justify-content: center;
            margin: 4px 0 14px;
            &-reviews {
              @include swap_direction(margin, 0 0 0 -4px);
            }
          }
          .elc-read-reviews {
            display: none;
          }
          .elc-reviews-number {
            font-family: inherit;
            font-size: 16px;
            color: $color-black;
            font-weight: normal;
          }
          .elc-star-icon {
            width: 18px;
            height: 18px;
          }
          .elc-star-overlay {
            background-color: $cr19-dark-pink;
            background: url('#{$base-theme-path}img/icons/src/gg_star_pink.svg') 95% 50% no-repeat;
            mask: none;
            -webkit-mask: none;
          }
        }
        &-short-description-wrapper {
          font-family: $base-font-family;
          font-size: 16px;
          letter-spacing: 0;
          line-height: get-line-height(16px, 19px);
          order: 3;
          min-height: 40px;
          margin: 0;
          text-align: center;
          text-decoration: none;
          @include breakpoint($landscape-up) {
            margin-top: unset;
            min-height: 25px;
          }
          .sd-search-gnav-input-field & {
            color: $color-white;
          }
        }
        &-prices-wrapper,
        &-prices-clickable-wrapper {
          order: 4;
          padding: 0;
          .elc-product-price-row-wrapper {
            padding-top: 0;
            justify-content: center;
          }
          .elc-product-original-price,
          .elc-product-price,
          .elc-product-price-range {
            font-size: 16px;
            font-weight: 100;
            font-family: $header-font-bold;
            text-align: center;
          }
          .elc-product-original-price {
            color: $color-black;
          }
          .elc-product-price {
            color: $color-red;
          }
        }
        &-full-cta-wrapper {
          background: none;
          box-shadow: none;
          margin: 10px auto 30px;
          order: 6;
          padding: 0;
          position: inherit;
          width: 320px;
          z-index: auto;
          @include breakpoint($medium-up) {
            margin-top: auto;
            max-width: 100%;
          }
          .sd-search-gnav-input-field & {
            width: auto;
          }
          .elc-add-to-bag-button {
            padding: 0 30px;
            font-family: $header-font-bold;
            background-color: $cr19-dark-pink;
            border-color: $cr19-dark-pink;
            color: $color-white;
            overflow: unset;
            text-transform: uppercase;
          }
          .elc-add-to-bag-button-disabled {
            background-color: $cr19-bg-dark;
            border-color: $cr19-bg-dark;
          }
          .elc-add-to-bag-button,
          .elc-add-to-bag-button-disabled {
            width: 320px;
            @include breakpoint($medium-up) {
              width: auto;
              margin: auto;
              max-width: 100%;
            }
          }
          .elc-add-to-bag-sku-inventory-status-message {
            margin: 0;
            width: 100%;
            position: absolute;
            bottom: 40px;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            & + .elc-add-to-bag-button {
              background-color: $cr19-bg-dark;
              border-color: $cr19-bg-dark;
              cursor: not-allowed;
            }
          }
          .elc-add-to-bag-sku-inventory-status-message-message {
            color: $cr19-error;
          }
          .elc-product-cta-button-wrapper {
            div {
              display: flex;
              flex-direction: column;
            }
          }
          .elc-limited-remaining-block {
            margin: 15px 0;
          }
          .elc-limited-remaining-message {
            @include main-font;
            color: $color-red;
          }
        }
      }
    }
    &-carousel-slider-view {
      .elc-slider-view-wrapper {
        .slick-slide {
          display: flex;
          flex-direction: column;
          padding: 0;
          .elc-product-brief {
            .elc-carousel-section-wrapper {
              // disable image carousel, specific style for cross sell only
              .elc-slider-arrow-wrapper,
              .elc-slider-dots-list,
              .slick-slide.slick-cloned {
                display: none;
              }
              .slick-slide:not(.slick-cloned) ~ .slick-slide {
                display: none;
              }
              .slick-track {
                transform: unset !important;
              }
              // end image carousel disable code
            }
          }
        }
      }
    }
  }
}
